import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderComp2";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import Seo from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/placeholder.png';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";
// import "../scss/main.scss";

export default function Story2024() {
  useEffect(() => {
    function checkUTMParameters() {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var utmSource = urlParams.get("utm_source");
      var utmMedium = urlParams.get("utm_medium");

      // Check if both utm_source and utm_medium are present and have the correct values
      if (utmSource === "specific_source" && utmMedium === "referrer") {
        // Redirect the user to the desired page only if the redirect flag is not set
        if (!sessionStorage.getItem("redirected")) {
          sessionStorage.setItem("redirected", "true");
          navigate("/submit-story-2024-pp/");
        }
      }
    }

    // Call the function when the DOM is ready
    checkUTMParameters();
  }, []);

  return (
    <>{ /* <SEO title="Welcome" keywords={[`great`, `british`, `dogs`]} /> */ }
    <SEO
      title="greatbritishdogs.co.uk | Submit story 2024"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div css={css`
                background-color: #004030 !important;
                min-height: calc(100vh - 84px) !important;
                `}>
            <div css={css`
                width: 100% !important; max-width: 1060px !important; padding-top: 50px !important; padding-right: 1rem !important; padding-left: 1rem !important; margin: 0 auto !important;`}>
            <h1 css={css`
                display: none !important;
                `}>
              Submit your dog story for a chance to win
            </h1>
            <h2 css={css`
                display: none !important;
                `}>Tell us what makes your dog great for a chance to be featured and to win an incredible pooch-themed prize</h2>
            <div class="flex">

            <div css={css`
                display: table;
                width: 100%;
            
                @media screen and (max-width: 768px) {
                    display: block;
                }
                `}>

                <div css={css`
                display: table-cell;
                vertical-align: top;
                width: 55%;
                left: 0;
            
                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                    left: auto;
                    padding-right: 0%;
                }
                `}>

                    <img src="https://www.greatbritishdogs.co.uk/winalot-2024-comp/img/comp-dog-img.png" alt="Share your dog stories to win graphic" width="792" height="753" style={{display: 'block', width: '100%', height: 'auto'}}></img>

                </div>

                <div css={css`
                display: table-cell;
                vertical-align: top;
                right: 0;
                width: 45%;
            
                @media screen and (max-width: 768px) {
                    display: block;
                    width: 100%;
                    right: auto;
                    padding-left: 0%;
                    margin-top: -90px;
                }
                `}>

                    <img src="https://www.greatbritishdogs.co.uk/winalot-2024-comp/img/comp-win-graphic.png" alt="Share your dog stories to win graphic" width="750" height="407" style={{display: 'block', width: '100%', height: 'auto'}}></img>
                    <h2 css={css`
                color: #fff;
                font-family: 'Source Sans 3', sans-serif;
                font-weight: 500;
                font-size: 1.4em;
                line-height: 1.3em;
                margin: 20px auto 30px auto;
                text-align: center;
                `}>Tell us why you love your dog for a chance to be featured, and maybe even win one of these fantastic prizes</h2>

                    <div css={css`
                display: table;
                width: 100%;
                border-radius: 10px;
                margin-bottom: 30px;
                background-color: #091E18;
            
                @media screen and (max-width: 350px) {
                display: block;
                }
                `}>

                        <div css={css`
                display: table-cell;
                left: 0;;
                vertical-align: middle;
                width: 25%;
                background-image: url('https://www.greatbritishdogs.co.uk/winalot-2024-comp/img/comp-prize-img.jpg');
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 10px;
            
                @media screen and (max-width: 1150px) {
                    width: 35%;
                }
            
                @media screen and (max-width: 768px) {
                    width: 130px;
                    height: 130px;
                }
            
                @media screen and (max-width: 350px) {
                    display: block;
                    left: auto;
                    width: 100%;
                    height: 200px;
                    border-radius: 10px 10px 0 0;
                }
                `}>

                        </div>

                        <div css={css`
                display: table-cell;
                right: 0;
                vertical-align: middle;
                width: 75%;
                background-color: #091E18;
                color: #fff;
                font-family: "Roboto Slab", serif;
                font-size: 1em;
                font-weight: 400;
                border-radius: 0px 10px 10px 0px;
            
                @media screen and (max-width: 1150px) {
                    width: 65%;
                }
            
                @media screen and (max-width: 768px) {
                    width: calc(100% - 130px);
                }
            
                @media screen and (max-width: 350px) {
                    display: block;
                    right: auto;
                    width: 100%;
                    border-radius: 0px 0px 10px 10px;
                }
                `}>

                            <div css={css`padding: 25px;`}>
                                You could win paw-some prizes from Winalot®, worth up to £2,000!<br></br><a href="https://www.greatbritishdogs.co.uk/terms-and-conditions/#form" target="_blank" className="learn-more-text-link" style={{textDecoration: 'underline', fontWeight: '600'}}>Prize details</a>
                            </div>

                        </div>

                    </div>

                    <h2 css={css`
                color: #fff;
                font-family: 'Source Sans 3', sans-serif;
                font-weight: 500;
                font-size: 1.4em;
                line-height: 1.3em;
                margin: 0px auto 30px auto;
                text-align: center;
                `}>This competition is now closed</h2>

                    {/*<div className="enter-now-button"
  onClick={(e) => {
    e.preventDefault();
    window.location.href = "https://www.greatbritishdogs.co.uk/submit-story-2024-form/";
  }} css={css`text-align: center;
    font-weight: 600;
    font-size: 1em;
    margin-bottom: -20px;
    color: #fff;
    width: 130px;
    padding: 12px 0px;
    border-radius: 15px;
    margin: 0 auto;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #AE456C;

    @media screen and (max-width: 600px) {
        font-size: 1.1em;
    }

    @media screen and (max-width: 414px) {
        max-width: 100%;
    }`}>Enter now</div>*/}

                </div>

            </div>

            </div>
            <br /><br />
          </div>
      </div>
      </>
    );
}